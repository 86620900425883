import Slider from "../components/home/Slider";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";

function Home() {
  return (
    <>
      <Header />
      <Slider />
      {/* <Showcase /> */}
      <Footer />
    </>
  );
}

export default Home;
